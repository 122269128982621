video {
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5
}
.video-wrapper {
  border: 2px solid #000;
  height: 100vh;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #000;
}
@media only screen and (max-width: 600px) {
  .video-wrapper {
    height: 26vh;
  }
}
.header {
  position: relative;
  margin-left: 80px;
  color: white;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 1);
  width: 500px;
}
.header-text {
  font-size: 100px;
  font-family: Trebuchet MS;
  /* font-weight: "bold"; */
}
